import { TRACKING_EVENTS } from '@/core/tracking/constants';

import {
    AcademicCapIcon,
    ChartBarSquareIcon,
    GiftIcon,
    RectangleStackIcon,
} from '@heroicons/react/24/outline';
import {
    AcademicCapIcon as AcademicCapSolidIcon,
    ChartBarSquareIcon as ChartBarSquareSolidIcon,
    GiftIcon as GiftSolidIcon,
    RectangleStackIcon as RectangleStackSolidIcon,
} from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { NavItemNew } from '@/app/navigation/components/New/NavItemNew';
import { getWorkspaceHomeUrl } from '@/app/navigation/helpers';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { track } from '@/core/tracking';

import type { ReactNode } from 'react';

const TOP_LEVEL_NAVBAR_ITEMS: {
    identifier: string;
    link: string | ((metadata: { defaultWorkspaceId: string }) => string);
    isActive: (currentPathname: string) => boolean;
    labelKey: string;
    icon: ReactNode;
    activeIcon: ReactNode;
}[] = [
    {
        identifier: 'funnels',
        link: () => getWorkspaceHomeUrl(),
        isActive: (currentPathname) =>
            currentPathname === '/' || currentPathname === '/workspaces/[id]',
        labelKey: 'home',
        icon: <RectangleStackIcon className="size-5 rotate-90" />,
        activeIcon: <RectangleStackSolidIcon className="size-5 rotate-90" />,
    },
    {
        identifier: 'performance',
        link: '/dashboard',
        labelKey: 'dashboard',
        isActive: (currentPathname) => currentPathname === '/dashboard',
        icon: <ChartBarSquareIcon className="size-5" />,
        activeIcon: <ChartBarSquareSolidIcon className="size-5" />,
    },
    {
        identifier: 'academy',
        link: '/achievements',
        labelKey: 'achievements',
        isActive: (currentPathname) => currentPathname.startsWith('/achievements'),
        icon: <AcademicCapIcon className="size-5" />,
        activeIcon: <AcademicCapSolidIcon className="size-5" />,
    },
    {
        identifier: 'referrals',
        link: '/referrals',
        labelKey: 'referrals',
        isActive: (currentPathname) => currentPathname === '/referrals',
        icon: <GiftIcon className="size-5" />,
        activeIcon: <GiftSolidIcon className="size-5" />,
    },
];

export const TopLevelNavNew = () => {
    const { pathname } = useRouter();
    const { defaultWorkspace } = useWorkspaces();

    const currentlyActiveItem = useMemo(() => {
        return TOP_LEVEL_NAVBAR_ITEMS.find((item) => item.isActive(pathname));
    }, [pathname]);

    const linkMetadata = useMemo(
        () => ({
            defaultWorkspaceId: defaultWorkspace?.id,
        }),
        [defaultWorkspace],
    );

    return (
        <div className="flex items-center gap-3">
            {TOP_LEVEL_NAVBAR_ITEMS.map(
                ({ link, labelKey, icon, isActive, activeIcon, identifier }) => {
                    const active = isActive(pathname);

                    return (
                        <NavItemNew
                            key={labelKey}
                            link={typeof link === 'function' ? link(linkMetadata) : link}
                            labelKey={labelKey}
                            active={active}
                            icon={active ? activeIcon : icon}
                            onClick={() => {
                                track(TRACKING_EVENTS.navigation.topLevelItems.itemClicked, {
                                    selected: identifier,
                                    current: currentlyActiveItem?.identifier,
                                });
                            }}
                        />
                    );
                },
            )}
        </div>
    );
};
